import React from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import moment from 'moment';

import { getDateFromUTCString } from 'utils/time';

function ChatItem({ isSender, createdAt, text }) {
  return (
    <li className={cN(
      'chat-item list relative',
      {
        right: isSender,
        left: !isSender,
        white: isSender,
      },
    )}
    >
      <div className={cN('message-container', { 'message-container-right': isSender })}>
        <div className="item-text word-wrap br4 pv2 ph3 tl lh-copy flex flex-column">
          <span className={cN({ white: isSender })}>{text}</span>
          <span className={cN('f6', { white: isSender })}>
          {`${moment.utc(getDateFromUTCString(createdAt)).local().format('h:mm A')}`}
          </span>
        </div>
      </div>
    </li>
  );
}

ChatItem.propTypes = {
  isSender: PropTypes.bool,
  text: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

ChatItem.defaultProps = {
  isSender: false,
}

export default ChatItem;
