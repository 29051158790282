import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import Select from './Select';
import map from 'lodash/map';
import every from 'lodash/every';
import update from 'immutability-helper';
import Linkify from 'react-linkify';
import classNames from 'classnames';

const isQuantityEqual = (comparableA, comparableB) => {
  if (!(comparableB && comparableA)) {
    return false;
  }

  const fields = ['unit', 'value', 'display'];
  return every(fields, (fieldName) => comparableA[fieldName] === comparableB[fieldName]);
};

class Quantity extends PureComponent {
  state = {
    unit: undefined,
    value: undefined,
    code: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { prevProps = {} } = prevState;
    const newState = {
      prevProps: nextProps,
    };
    if ('answer' in nextProps && !isQuantityEqual(prevProps.answer, nextProps.answer)) {
      const { answer: v = {} } = nextProps;
      const { unit, value, code } = v;
      newState.unit = unit;
      newState.value = value;
      newState.code = code;
    }
    return newState;
  }

  onQuantityChange = ({ target: { value: v } }) => {
    const { onComplete, onAnswer, id } = this.props;
    const { unit, code } = this.state;
    const value = v ? Number(v) : v;
    this.setState(
      (state) => update(state, { value: { $set: value } }),
      () => {
        onAnswer(id, { unit, value, code });
      },
    );
    if (code !== undefined && value !== '') {
      onComplete();
    }
  };

  onSelect = (u, $el) => {
    const { onAnswer, onComplete, required, id } = this.props;
    const { value } = this.state;
    if (u) {
      const { title: unit, value: code } = $el.props;

      this.setState(
        (state) => update(state, { unit: { $set: unit }, code: { $set: code } }),
        () => {
          onAnswer(id, { unit, value, code });
        },
      );
    } else {
      this.setState(
        (state) => update(state, { unit: { $set: undefined }, code: { $set: undefined } }),
        () => {
          onAnswer(id, { unit: undefined, value, code: undefined });
        },
      );
    }
    if (required && value !== undefined && value !== '') {
      onComplete();
    }
  };

  render() {
    // eslint-disable-next-line  
    const { extension, onChange, value: valueFromProps, disabled, readOnly, isAnswerType, text, viewSummary, ...otherProps } = this.props;
    const { unit, value } = this.state;

    const items = map(extension, ({ valueCoding: { code, display } }) => ({
      value: code,
      key: code,
      label: display,
    }));
    const _unit = value !== undefined && Object.hasOwn(value, 'unit') ? value.unit : unit;
    const _value = value !== undefined && Object.hasOwn(value, 'value') ? value.value : value;
    const selectAfter = (
      <Select
        value={_unit}
        aria-label="Quantity Unit Input"
        style={{ width: 100 }}
        placeholder="Unit"
        onChange={this.onSelect}
        items={items}
        disabled={disabled}
      />
    );

    return (
      <div className="ma3 w-80 center">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <Input
          value={_value}
          aria-label="Quantity Value Input"
          addonAfter={selectAfter}
          type="number"
          onChange={this.onQuantityChange}
          disabled={disabled}
          {...otherProps}
        />
      </div>
    );
  }
}

export default Quantity;

Quantity.propTypes = {
  answer: PropTypes.object,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unit: PropTypes.string,
    code: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onAnswer: PropTypes.func,
  onComplete: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  extension: PropTypes.arrayOf(
    PropTypes.shape({
      valueCoding: PropTypes.shape({
        code: PropTypes.string,
        display: PropTypes.string,
      }),
    }),
  ).isRequired,
};

Quantity.defaultProps = {
  extension: undefined,
  disabled: false,
};
