import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Questions from 'scenes/Questionnaire/components/Questions';

export default class Choice extends PureComponent {
  static propTypes = {
    onSetQuestion: PropTypes.func.isRequired,
    questionIndex: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    answer: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
        valueType: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        type: PropTypes.string,
      })),
    ]).isRequired,
    readOnly: PropTypes.bool.isRequired,
    repeats: PropTypes.bool.isRequired,
  }

  handleSetQuestion = () => {
    const { onSetQuestion, questionIndex } = this.props;
    onSetQuestion(questionIndex);
  }

  render() {
    const { readOnly, repeats } = this.props;
    const wrapperProps = readOnly
      ? {
        className: 'ma3 mb4',
      } : {
        className: 'ma3 mb4 pointer',
        onClick: this.handleSetQuestion,
      };
    return (
      <div data-testid='choice-answer' {...wrapperProps}>
        {repeats ?
          <Questions.MultiChoice {...this.props} /> :
          <Questions.SingleChoice {...this.props} />
        }
      </div>
    );
  }
}
