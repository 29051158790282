import { getCurrentSession } from 'services/auth';
import { logoutUser } from 'redux/auth/actions';
import KeetApi from '@keethealth/api-client';
import Client from './client';

const notifyError = (error) => {
  throw error;
};

const logout = () => {
  Client.getStore().dispatch(logoutUser());
};

const ApiClient = new KeetApi({
  apiUrl: process.env.REACT_APP_API_ROOT_URL || 'http://localhost:5000',
  reactAppSiteUrl: process.env.REACT_APP_SITE_URL,
  TTL: parseInt(process.env.REACT_APP_AUTH_TTL, 10),
  API_TIMEOUT: 30 * 1000,
  onUnauthorized: logout,
  getCurrentSession,
  notifyError,
  // onError: handleErrors,
});

export default ApiClient;
