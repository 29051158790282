import { NOTIFICATION_SHOW, NOTIFICATION_HIDE } from './constants';

export const showNotification = (text, type = 'info', status, url) => ({
  type: NOTIFICATION_SHOW,
  payload: { text, type, status, url },
});

export const hideNotification = index => ({
  type: NOTIFICATION_HIDE,
  payload: { index },
});
