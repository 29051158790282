import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

const LikertLabel = ({ text }) => (
  <p className="b f5 tc" style={{ overflowWrap: 'break-word' }}>
    <Linkify properties={{ target: '_blank' }}>
      {text}
    </Linkify>
  </p>
);

LikertLabel.propTypes = {
  text: PropTypes.string,
};

LikertLabel.defaultProps = {
  text: '',
};

export default LikertLabel;
