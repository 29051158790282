import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from 'redux/auth/reducer';
import notificationReducer from 'redux/notification/reducer';
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  notification: notificationReducer,
});
export default createRootReducer
