import React, { Component } from 'react';
import Questionnaire from 'scenes/Questionnaire';
import Client from 'App/client';

class QRTask extends Component {
  componentDidMount = () => {
    this.setHeaders();
  }

  setHeaders = () => {
    const signatureFromStorage = window.sessionStorage.getItem('@keethealth/signature');

    Client.setHeaders({
      Authorization: `Basic ${signatureFromStorage}`,
    });
  }

  render() {
    return <Questionnaire {...this.props} getNextActivity={() => {}} />;
  }
}

export default QRTask;
