import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'common/Badge';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

const MessageBadge = ({ children, hasUnread, ...badgeProps }) => {
  const { count, ...rest } = badgeProps;
  let showProps = { dot: hasUnread };

  if (count) {
    showProps = { count: hasUnread ? count : 0 };
  }

  return (
    <Badge {...showProps} {...rest}>
      {children}
    </Badge>
  );
};

MessageBadge.propTypes = {
  offset: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.node,
  hasUnread: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

MessageBadge.defaultProps = {
  offset: [5, -5],
  count: undefined,
};

export default graphql(gql`
  query unreadMessages { messages(page: 1, size: 1, read: false) { id } }
`, {
  options: {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
  },
  props: ({ data: { messages = [] } }) => ({
    hasUnread: messages.length > 0,
  }),
})(MessageBadge);
