import React from 'react';
import HeaderTab from 'common/HeaderTab';
import keetLogo from 'assets/images/keet-text-logo.png';
import ptnowLogo from 'assets/images/pt-now-header-logo.png';
import HeaderProfile from './HeaderProfile';
import HeaderMobile from './HeaderMobile';
import MessageBadge from './MessageBadge';
import '../header.scss';

function Header() {
  return (
    <div data-testid="header">
      <header id="keet-header" className="bg-white bb fw3 f5">
        <div className="mw8 w-100 center flex flex-shrink-0 items-center content-between">
          <div className="flex w-100 items-center justify-center">
            <div className="flex flex-auto pl3">
              <img id="header-logo" src={process.env.REACT_APP_THEME == 'ptnow' ? ptnowLogo: keetLogo} alt="keet-logo" />
            </div>
            <div className="dn db-l">
              <div className="flex">
                <div className="br flex fw4">
                  {renderTabs()}
                </div>
                <HeaderProfile />
              </div>
            </div>
          </div>
          <div className="w-100 db dn-ns db-m dn-l">
            <HeaderMobile />
          </div>
        </div>
      </header>
    </div>
  );

  function renderTabs() {
    const menu = [
      {
        key: 'my_tasks',
        to: '/my_tasks',
        title: 'My Tasks',
      },
      {
        key: 'progress',
        to: '/progress',
        title: 'Progress',
      },
      {
        key: 'history',
        to: '/history',
        title: 'History',
      },
      {
        key: 'messages',
        to: '/messages',
        title: (
          <MessageBadge>
            Messages
          </MessageBadge>
        ),
      },
    ];
    return menu.map(tabs => (
      <HeaderTab key={tabs.key} {...tabs} />
    ));
  }
}

export default Header;
