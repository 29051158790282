import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';

import CURRENT_PROFILE from 'graph/queries/currentUser';
import ChatMessageForm from './ChatMessageForm';
import WindowTitle from './WindowTitle';

function CreateChat({ patientId, practitioner: { id, given, family }, onSelectConversation }) {
  return (
    <div className="flex flex-column flex-auto">
      <WindowTitle
        name={`Create Conversation with ${given} ${family}`}
      />
      <div className="flex-auto" />
      <ChatMessageForm
        isCreatingChat
        practitionerId={id}
        patientId={patientId}
        onSelectConversation={onSelectConversation}
      />
    </div>
  );

}

CreateChat.propTypes = {
  practitioner: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  onSelectConversation: PropTypes.func.isRequired,
};

CreateChat.defaultProps = {
  patientId: '',
};

export default compose(
  graphql(CURRENT_PROFILE, {
    props: ({ data: { currentUser } }) => ({
      patientId: currentUser.id,
    }),
  }),
)(CreateChat);
