import React from 'react';
import PropTypes from 'prop-types';

import { ButtonKeetUI } from '@keethealth/ui-components';
import history from 'utils/history';
import { logoutUser } from 'redux/auth/actions';
import { useDispatch } from 'react-redux';
import './error_page.scss';

function ErrorPage({ resetErrorBoundary }) {
  const dispatch = useDispatch();

  return (
    <div className="flex justify-center items-center flex-column w-100 h-100 absolute top-0 pa3 errorPage">
      <h1>Something went wrong.</h1>
      <h2>If this error persists, please contact customer support.</h2>
      {renderButtons()}
    </div>
  );

  function handleRefresh() {
    resetErrorBoundary();
    window.location.reload();
  }

  function handleLogout() {
    resetErrorBoundary();
    dispatch(logoutUser());
  }

  function handleHome() {
    resetErrorBoundary();
    history.push('/my_tasks');
  }

  function renderButtons() {
    return (
      <div className="flex">
      <ButtonKeetUI
        className="flex mr3"
        onClick={handleHome}
      >
        Back to Home
      </ButtonKeetUI>
      <ButtonKeetUI
        className="flex mr3"
        onClick={handleRefresh}
      >
        Refresh Page
      </ButtonKeetUI>
      <ButtonKeetUI
        onClick={handleLogout}
      >
        Log Out
      </ButtonKeetUI>
    </div>
    )
  }
}

ErrorPage.propTypes = {
  resetErrorBoundary: PropTypes.func,
}

export default ErrorPage;
