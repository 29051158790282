import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ButtonKeetUI } from '@keethealth/ui-components';

import TextField from 'common/TextField';
import UPDATE_PASSWORD from 'graph/mutations/changePassword';
import { showNotification } from 'redux/notification';
import { fieldHasErrors, fieldErrorText } from 'utils/utils';

const mapDispatchToProps = dispatch => (
  {
    notification: () => { dispatch(showNotification('Password Successfully Changed', 'success')); },
  }
);

export class PasswordSettings extends PureComponent {
  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    notification: PropTypes.func.isRequired,
  }

  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    errors: [],
    isSubmitDisabled: false,
  }

  handleInputChange = name => (value) => {
    this.setState({
      [name]: value,
    });
  }

  handleSubmitPassword = async (event) => {
    event.preventDefault();
    this.setState({ isSubmitDisabled: true });
    const { changePassword, notification } = this.props;
    const { currentPassword, newPassword, newPasswordConfirmation } = this.state;
    const res = await changePassword(currentPassword, newPassword, newPasswordConfirmation);
    this.setState({
      errors: res.data.changePassword.errors,
    });
    if (res.data.changePassword.errors.length === 0) {
      notification();
      this.setState({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      });
    }
    this.setState({ isSubmitDisabled: false });
  }

  render() {
    const { currentPassword, newPassword, newPasswordConfirmation, errors, isSubmitDisabled } = this.state;
    return (
      <div className="flex flex-column justify-center items-center flex-auto overflow-auto">
        <form className="w5" onSubmit={this.handleSubmitPassword}>
          <TextField
            id="settings-currentPassword"
            type="password"
            label="Current Password"
            value={currentPassword || ''}
            onChange={this.handleInputChange('currentPassword')}
            className="mv3"
            error={fieldHasErrors(errors, 'currentPassword')}
            errorText={fieldErrorText(errors, 'currentPassword')}
          />
          <TextField
            id="settings-newPassword"
            type="password"
            label="New Password"
            value={newPassword || ''}
            onChange={this.handleInputChange('newPassword')}
            className="mv3"
            error={fieldHasErrors(errors, 'password')}
            errorText={fieldErrorText(errors, 'password')}
          />
          <TextField
            id="settings-newPasswordConfirmation"
            type="password"
            label="Confirm New Password"
            value={newPasswordConfirmation || ''}
            onChange={this.handleInputChange('newPasswordConfirmation')}
            className="mv3"
            error={fieldHasErrors(errors, 'passwordConfirmation')}
            errorText={fieldErrorText(errors, 'passwordConfirmation')}
          />
          <ButtonKeetUI
            className="center mv3"
            type="submit"
            disabled={isSubmitDisabled}
          >
            SAVE
          </ButtonKeetUI>
        </form>
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  graphql(UPDATE_PASSWORD, {
    props: ({ mutate }) => ({
      changePassword: (currentPassword, newPassword, newPasswordConfirmation) => mutate({
        variables: {
          input: {
            currentPassword,
            password: newPassword,
            passwordConfirmation: newPasswordConfirmation,
          },
        },
      }),
    }),
  }),
)(PasswordSettings);
