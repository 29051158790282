import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import queries from 'graph/queries';
import CURRENT_USER from 'graph/queries/currentUser';
import mutations from 'graph/mutations';
import { getMessagingOtherParty } from 'utils/chat';
import { LoaderKeetUI } from '@keethealth/ui-components';
import Chat from './Chat';
import ChatMessageForm from './ChatMessageForm';
import WindowTitle from './WindowTitle';

function Window({ conversation, conversation: { messages }, userId, readMessage, loading }) {

  useEffect(() => {
    readMessages();
  }, [conversation])

  const otherParty = getMessagingOtherParty(userId, conversation);
  return (
    <div className="messages-window flex flex-auto flex-column tc">
      <WindowTitle
        name={conversation.topic || ''}
        subtitle={loading ? '' : `${otherParty.firstName} ${otherParty.lastName}`}
      />
      {loading && <LoaderKeetUI color="primary" />}
      {conversation &&
        <Chat
          patientId={userId}
          messages={conversation.messages}
        />
      }
      {conversation &&
        <ChatMessageForm conversationId={conversation.id} />
      }
    </div>
  );
  function readMessages() {
    forEach(messages, (message) => {
      if (message.createdBy !== userId && isEmpty(message.readAt)) {
        readMessage({ id: message.id });
      }
    });
  }
}

Window.propTypes = {
  conversation: PropTypes.object,
  userId: PropTypes.string,
  readMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Window.defaultProps = {
  conversation: {
    id: '',
    messages: [],
    sender: {},
    receiver: {},
  },
  userId: '',
  loading: false,
};

export default compose(
  graphql(mutations.readMessage, {
    props: ({ mutate }) => ({
      readMessage: ({ id }) => mutate({
        variables: { input: { id } },
      }),
    }),
  }),
  graphql(queries.conversation, {
    props: ({ data: { loading, conversation }, ownProps }, lastProps) => ({
      conversation,
      loading: loading && (lastProps && lastProps.conversation ? ownProps.conversationId !== lastProps.conversation.id : true),
    }),
    options: ({ conversationId }) => ({
      variables: { id: conversationId },
      pollInterval: 10000,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      userId: currentUser && currentUser.id,
    }),
  }),
)(Window);
