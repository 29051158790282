import React from 'react';
import PropTypes from 'prop-types';

function TitlePanel({ id, className, children }) {
  return (
    <div id={id} className="mh3 mt3 mw8 w-100 center h4-ns" data-testid="title-panel">
      <div className={`ba-ns bb bt mh3-ns mh0 h-100 bg-white ${className}`}>
        {children}
      </div>
    </div>
  );
}

TitlePanel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TitlePanel.defaultProps = {
  id: '',
  className: '',
  children: <noscript />,
};

export default TitlePanel;
