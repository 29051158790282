import React from 'react';

import './EducationContent.scss';

export default function EducationContent() {
  return (
    <div>
    </div>
  )
}
