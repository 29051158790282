import moment from 'moment';

export const formatChatTime = time => (
  getDateFromUTCString(time).calendar(null, {
    sameDay: 'h:mma',
    lastDay: 'ddd',
    lastWeek: 'ddd',
    sameElse: 'MMM D',
  })
);

export function getDateFromUTCString(utcStr) {
  return moment(utcStr.replace(/ UTC/, '+00:00'));
}
