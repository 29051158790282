import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import history from 'utils/history';
import thunk from 'redux-thunk';

import { getCurrentSession } from 'services/auth';
import createRootReducer from '../reducers';
import { showNotification } from 'redux/notification';
import config from './config';
import possibleTypes from '../graph/fragments/possibleTypes.json';
import 'setimmediate';

class Client {
  static setHeaders(headers) {
    this.headers = headers;
  }

  static get() {
    if (!this.client) {
      const httpLink = new HttpLink({
        uri: config.apiUrl,
      });

      const authLink = setContext(async (req, { headers }) => {
        // get the authentication token from local storage if it exists
        if (!this.headers) {
          try {
            const session = await getCurrentSession();
            const authorization = session.accessToken && {
              Authorization: `Bearer ${session.accessToken}`,
            };
            return {
              headers: {
                ...headers,
                ...authorization,
              },
            };
          } catch (e) {
            console.log('error:client:get', e);
            return {};
          }
        } else {
          return {
            headers: {
              ...headers,
              ...this.headers,
            },
          };
        }
      });

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => this.getStore().dispatch(showNotification(message, 'error')));
        }

        if (networkError) console.log(`[Network error]: ${networkError}`);
      });

      const cache = new InMemoryCache({
        addTypename: true,
        dataIdFromObject: obj => obj.id,
        possibleTypes,
      });

      const link = ApolloLink.from([
        authLink,
        errorLink,
        httpLink,
      ]);

      this.client = new ApolloClient({
        link,
        cache,
      });
    }

    return this.client;
  }

  static getStore() {
    if (!this.store) {
      // Build the middleware for intercepting and dispatching navigation actions
      const middleware = routerMiddleware(history);
      this.store = createStore(
        createRootReducer(history),
        {}, // initial state
        compose(
          applyMiddleware(
            thunk,
            middleware,
          ),
          (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
        ),
      );
    }

    return this.store;
  }

  static clearStore() {
    if (this.client) {
      this.client.clearStore();
    }
  }
}

export default Client;
