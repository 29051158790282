import { gql } from '@apollo/client' ;

export default gql`
  mutation completeNpsSurvey($input: CompleteNpsSurveyInput!) {
    completeNpsSurvey(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;
