import find from 'lodash/find';

export const getMessagingOtherParty = (userId, conversation) => {
  const users = [
    conversation.sender,
    conversation.receiver,
  ];
  return find(users, user => (user && user.id !== userId)) || {
    id: '',
    firstName: '',
    lastName: '',
  };
};
