import { gql } from '@apollo/client' ;

import PATIENT_FRAGMENT from 'graph/fragments/patient';
import USER_FRAGMENT from 'graph/fragments/currentUser';

export default gql`
  query currentUser {
    currentUser {
      ...currentUser
    }
    patientRecord {
      ...patient
    }
  }
  ${USER_FRAGMENT}
  ${PATIENT_FRAGMENT}
`;
