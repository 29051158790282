import React from 'react';
import PropTypes from 'prop-types';
import PRACTITIONERS from 'graph/queries/practitioners';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import isEmpty from 'lodash/isEmpty';

import ChatSuggestion from './ChatSuggestion';

function ChatSuggestions({ practitioners, onBeginCreation }) {
  return (
    <div className="h-100 message-scroll">
      {practitioners.map(
        practitioner => (
          <ChatSuggestion
            key={practitioner.id} name={`${practitioner.family}, ${practitioner.given}`} onCreate={onBeginCreation(practitioner)}
          />
        ))}
    </div>
  );
}

ChatSuggestions.propTypes = {
  practitioners: PropTypes.arrayOf({
    id: PropTypes.string,
    given: PropTypes.string,
    family: PropTypes.string,
  }),
  onBeginCreation: PropTypes.func.isRequired,
};

ChatSuggestions.defaultProps = {
  practitioners: [],
};

export default compose(
  graphql(PRACTITIONERS, {
    props: ({ data: { practitioners } }) => ({
      practitioners,
    }),
    options: ({ searchQuery }) => ({
      variables: {
        search: isEmpty(searchQuery) ? null : searchQuery,
      },
    }),
  }),
)(ChatSuggestions);
