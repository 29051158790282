import React from 'react';
import RCDropdown from 'rc-dropdown';
import PropTypes from 'prop-types';
import './dropdown.scss';

function Dropdown(props) {
  return (
    <div data-testid="dropdown">
      <RCDropdown {...props} />
    </div>
  );
}

Dropdown.propTypes = {
  trigger: PropTypes.array.isRequired,
  overlay: PropTypes.node.isRequired,
  animation: PropTypes.string.isRequired,
};

export default Dropdown;
