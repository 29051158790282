import React from 'react';
import PropTypes from 'prop-types';

function WindowTitle({ avatarUrl, subtitle, name }) {
  return (
    <div id="window-title" className="flex flex-shrink-0 flex-column items-center justify-center bb">
      {avatarUrl && <img className="mr2 br-100 w2 h2" src={avatarUrl} alt="avatar" />}
      <span>{name}</span>
      <span className="f6 silver">{subtitle}</span>
    </div>
  );
}

WindowTitle.propTypes = {
  avatarUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

WindowTitle.defaultProps = {
  avatarUrl: '',
  subtitle: '',
};

export default WindowTitle;
