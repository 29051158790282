import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Questions from 'scenes/Questionnaire/components/Questions';

export default class Slider extends PureComponent {
  static propTypes = {
    onSetQuestion: PropTypes.func.isRequired,
    questionIndex: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.number,
      valueType: PropTypes.string,
    }).isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  handleSetQuestion = () => {
    const { onSetQuestion, questionIndex } = this.props;
    onSetQuestion(questionIndex);
  }

  render() {
    const { readOnly } = this.props;
    const wrapperProps = readOnly
      ? {
        className: 'ma3 mb4',
      } : {
        className: 'ma3 mb4 pointer',
        onClick: this.handleSetQuestion,
      };
    return (
      <div data-testid='slider-answer' {...wrapperProps}>
        <Questions.Slider {...this.props} />
      </div>
    );
  }
}
