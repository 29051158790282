
/**
 * Takes User and Patient objects and returns as 1 object
 */
export const normalizeUser = ({ user, patient }) => {
  if (!(user && patient)) {
    return null;
  }

  const { username, organization } = user;

  return {
    currentUser: {
      ...patient,
      organization,
      username,
    },
  };
};
