import React from 'react';
import PropTypes from 'prop-types';
import './login.scss';

function LoginPanel({ className, children }) {
  return (
    <div id="login-panel" className={`flex-auto ba bg-white ma3 w-100 center ${className}`} data-testid="login-panel">
      {children}
    </div>
  );
}

LoginPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

LoginPanel.defaultProps = {
  className: '',
  children: <noscript />,
};

export default LoginPanel;
