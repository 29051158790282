import React from 'react';
import PropTypes from 'prop-types';
import './LikertLowerLabel.scss';

const LikertLowerLabel = ({ leftText, rightText }) => (
  <div className="flex justify-center">
    <div className="flex justify-between w-100 LikertLowerLabel">
      <div>{leftText}</div>
      <div>{rightText}</div>
    </div>
  </div>
);

LikertLowerLabel.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
};

LikertLowerLabel.defaultProps = {
  leftText: '',
  rightText: '',
};

export default LikertLowerLabel;
