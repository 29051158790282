import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from 'common/TextField';
import Props from 'utils/proptypes';
import isEmpty from 'lodash/isEmpty';
import Linkify from 'react-linkify';
import classNames from 'classnames';

export default class String extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onIncomplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.string,
      type: Props.questionnaire.answerType,
    }),
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    answer: {
      value: '',
      valueType: 'string',
    },
    isAnswerType: false,
    readOnly: false,
  }

  state = {
    value: this.props.answer.value,
  }

  componentDidMount() {
    const { onAnswer, id, onComplete, required } = this.props;
    const { value } = this.state;
    if (value === '') {
      onAnswer(id, {});
      if (!required) {
        onComplete();
      }
    }
  }

  handleChange = (value) => {
    const { onAnswer, id, onComplete, onIncomplete, required } = this.props;
    onAnswer(id, { value, valueType: 'string' });
    this.setState({
      value,
    });
    if (required && isEmpty(value)) {
      onIncomplete();
    } else {
      onComplete();
    }
  }

  render() {
    const { text, isAnswerType, readOnly } = this.props;
    const { value } = this.state;
    return (
      <div data-testid='string-question' className="ma3 w-80 center">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <TextField
          id="question-string"
          value={value}
          onChange={this.handleChange}
          rows={1}
          maxRows={1}
        />
      </div>
    );
  }
}
