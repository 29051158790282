import { gql } from '@apollo/client' ;

export default gql`
  mutation updateQuestionnaireResponse($input: UpdateQuestionnaireResponseInput!) {
    updateQuestionnaireResponse(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;
