import React from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';

const { Option } = AntSelect;

const Select = React.forwardRef((props, ref) => {
  const { onChange, children, onSelectChange, items, style, ...rest } = props;
  return (
    <AntSelect
      ref={ref}
      placeholder="Select"
      allowClear
      style={{
        width: '100%',
        ...style,
      }}
      showSearch
      getPopupContainer={(trigger) => trigger.parentNode}
      {...rest}
      onChange={onSelectChange || onChange}
    >
      {children ||
        items.map(({ key, value, label }) => (
          <Option key={key.toString()} value={value} title={label || value}>
            {label || value}
          </Option>
        ))}
    </AntSelect>
  );
});

Select.displayName = "Select";

Select.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  items: PropTypes.array,
};

Select.defaultProps = {
  children: undefined,
  onChange: undefined,
  onSelectChange: undefined,
  items: [],
  style: {},
};

Select.Option = AntSelect.Option;

export default Select;
