import React from 'react';
import PropTypes from 'prop-types'

export default function Tab({ label, name, handleTabChange, className }) {
    return (
        // eslint-disable-next-line       
        <li onClick={() => handleTabChange(name)}>
            <span className={className}>{label}</span>
        </li>
    )
}

Tab.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  handleTabChange: PropTypes.func,
  className: PropTypes.string,
}
