import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Empty } from 'antd';
import ChatGroups from "./ChatGroups";
import { getDateFromUTCString } from 'utils/time';

function Chat({ messages, patientId }) {
  const chatWindow = useRef(null);

  // TODO: remove sorting step from frontend at some point - API should be returning messages within a conversation sorted
  const unsorted = [...messages];
  const sorted = unsorted.sort(function sortByUnixTimeDesc(prevMsg, nextMsg) {
    const prevMsgSec = getUnixTime(prevMsg.createdAt);
    const nextMsgSec = getUnixTime(nextMsg.createdAt);

    return prevMsgSec > nextMsgSec ? 1 : -1;

    function getUnixTime(dateStr) {
      return getDateFromUTCString(dateStr).valueOf();
    }
  });

  // scroll to the bottom of the chat window by default to see most recent chats
  useEffect(() => {
    chatWindow.current.scrollTo(0, chatWindow.current.scrollHeight);
  });

  return (
    <>
      <ul ref={chatWindow} id="window-chat" className="dn-ns flex flex-auto flex-column overflow-auto pa3 ma0 mobile-window-chat">
        {renderItems()}
      </ul>
      <ul ref={chatWindow} id="window-chat" className="dn flex-m flex-l flex-auto flex-column overflow-auto pa3 ma0 desktop-window-chat">
        {renderItems()}
      </ul>
    </>
  );
  function renderItems() {
    if (isEmpty(sorted)) {
      return (
        <Empty
          className="flex flex-column justify-center items-center flex-auto"
          description={
            <span>
              This conversation has no secure messages.
            </span>
          }
        />
      );
    }
    return <ChatGroups messages={sorted} patientId={patientId} />
  }
}

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default Chat;
