import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-md';
import cN from 'classnames';
import './textfield.scss';

function Text({ className, ...rest }) {
  return (
    <TextField
      {...rest}
      className={cN('w-100', className)}
      data-testid="text-field"
    />
  );
}

Text.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Text.defaultProps = {
  className: '',
  disabled: false,
}

export default Text;
