import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const LikertButton = ({ isSelected, buttonText, disabled, onClick }) => (
  <Button
    shape="circle"
    type={isSelected ? 'primary' : 'dashed'}
    onClick={onClick}
    disabled={disabled}
    className="ma1 buttonStyle"
  >
    {buttonText}
  </Button>
);

LikertButton.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

LikertButton.defaultProps = {
  isSelected: false,
  buttonText: '',
  disabled: false,
  onClick: undefined,
};

export default LikertButton;
