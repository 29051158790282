import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import history from 'utils/history';
import PATIENT_RECORD from 'graph/queries/patientRecord';
import CURRENT_USER from 'graph/queries/currentUser';
import Dropdown, { Menu, MenuItem, Divider } from 'common/Dropdown';
import { logoutUser } from 'redux/auth/actions';

const mapDispatchToProps = dispatch => (
  {
    onLogout: () => { dispatch(logoutUser()); },
  }
);

function HeaderProfile({ patientRecord: { given, organization }, currentUser: { username }, onLogout }) {
  const menu = (
    <Menu onClick={handleClick}>
      <MenuItem key="username" className="inactiveMenuItem">
        <i className="fas fa-user mr2" />
        {username}
      </MenuItem>
      <Divider />
      <MenuItem key="settings">
        <i className="fas fa-cog mr2" />
        Settings
      </MenuItem>
      <MenuItem key="logout">
        <i className="fas fa-power-off mr2" />
        Log Out
      </MenuItem>
    </Menu>
  );
  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      animation="slide-up"
    >
      <div id="header-profile" className="flex items-center mr2 fw4">
        <div id="header-login-text" className="pr3 pl3 pointer silver">
          <div className="header-login-text">
            <span className="primary ttc f6">{given}</span>
            <br />
            <span className="primary ttc f6">{organization.name}</span>
            <i className="fas fa-angle-down ml2 fw-1" />
          </div>
        </div>
      </div>
    </Dropdown>
  );

  function handleClick(info) {
    const { key } = info;
    switch (key) {
      case 'logout':
        onLogout();
        break;
      default:
        history.push('/settings');
    }
  }
}

HeaderProfile.propTypes = {
  patientRecord: PropTypes.shape({
    id: PropTypes.string,
    given: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
    })
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
  }),
  onLogout: PropTypes.func.isRequired,
};

HeaderProfile.defaultProps = {
  patientRecord: {
    id: '',
    given: '',
    organization: {
      name: '',
    },
  },
  currentUser: {
    id: '',
    username: '',
  },
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  graphql(PATIENT_RECORD, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { patientRecord } }) => ({
      patientRecord,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      currentUser,
    }),
  }),
)(HeaderProfile);
