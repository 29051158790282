import AuthClient from '@keethealth/auth-client';

const scope = (process.env.REACT_APP_OAUTH2_SCOPES || '').replace(/,/g, ' ');

const client = new AuthClient({
  url: process.env.REACT_APP_OAUTH2_ISSUER_URL,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  /* eslint-disable-next-line */
  storage: localStorage,
});

export async function getCurrentSession() {
  return client.getCurrentSession();
}

export async function signOut() {
  return client.signOut();
}

export function authorize(opts = {}) {
  const { state, ...extras } = opts;

  return client.authorize({
    redirectUri: process.env.REACT_APP_LOGIN_URL,
    scope,
    state,
    audience: [process.env.REACT_APP_OAUTH2_AUDIENCE],
    extras,
  });
}

export async function checkForResponse() {
  return client.checkForResponse();
}

export function getCurrentUser() {
  return client.getCurrentUser();
}
