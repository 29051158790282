import { gql } from '@apollo/client' ;

import CARE_PLAN_ACTIVITY_FRAGMENT from 'graph/fragments/carePlanActivity';
import EPISODE_OF_CARE_FRAGMENT from 'graph/fragments/episodeOfCare';

export const carePlans = gql`
  query carePlans {
    carePlans {
      id
      status
      carePlanActivities {
        ...carePlanActivity
      }
    }
  }
  ${CARE_PLAN_ACTIVITY_FRAGMENT}
`;

export const carePlansComplete = gql`
  query carePlansComplete {
    carePlans {
      id
      status
      carePlanActivities(statuses: ["completed", "in_progress"]) {
        ...carePlanActivity
      }
    }
  }
  ${CARE_PLAN_ACTIVITY_FRAGMENT}
`;

export const carePlansIncomplete = gql`
  query carePlansIncomplete {
    carePlans {
      id
      status
      startDate
      endDate
      title
      episodeOfCare {
        ...episodeOfCare
      }
      carePlanActivities(statuses: ["not_started", "in_progress"]) {
        ...carePlanActivity
      }
    }
  }
  ${CARE_PLAN_ACTIVITY_FRAGMENT}
  ${EPISODE_OF_CARE_FRAGMENT}
`;

export const taskStats = gql`
  query carePlanStats {
    carePlanStats: carePlans {
      id
      incomplete: carePlanActivities(statuses: ["not_started", "in_progress"]) {
        id
        updatedAt
      }
      complete: carePlanActivities(status: "completed") {
        id
        updatedAt
      }
      total: carePlanActivities {
        id
        updatedAt
      }
    }
  }
`;

export const carePlanActivity = gql`
  query carePlanActivity($id: ID!) {
    carePlanActivity: questionnaire(id: $id) {
      ...carePlanActivity
    }
  }
  ${CARE_PLAN_ACTIVITY_FRAGMENT}
`;
