import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

import './ContentCardFooter.scss';

export default function ContentCardFooter({ children, status }) {
  return (
    <div className="content-card-footer">
      {children}
      {status === 'not_started' && (
        <div className="content-card-new">
          <Badge count={'NEW'} style={{ backgroundColor: '#da007b' }} />
        </div>
      )}
    </div>
  );
}

ContentCardFooter.propTypes = {
  children: PropTypes.node,
  status: PropTypes.string,
}
