import React from 'react';
import PropTypes from 'prop-types';

function ChatSuggestion({ name, onCreate }) {
  return (
    // eslint-disable-next-line
    <div className="pa3 flex items-center pointer" onClick={onCreate}>
      <p className="f5">{name}</p>
      <p className="silver"/>
    </div>
  );
}

ChatSuggestion.propTypes = {
  name: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default ChatSuggestion;
