import React from 'react';
import PropTypes from 'prop-types';

import ContentCardContent from "./ContentCardContent";
import ContentCardFooter from "./ContentCardFooter";
import './ContentCard.scss';

export default function ContentCard({ footer, children, status, ...rest }) {
  return (
    <div>
      <ContentCardContent {...rest}>
        {children}
      </ContentCardContent>
      <ContentCardFooter status={status}>
        {footer}
      </ContentCardFooter>
    </div>
  )
}

ContentCard.propTypes = {
  footer: PropTypes.node,
  children: PropTypes.node,
  status: PropTypes.string,
}
