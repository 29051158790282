import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { LoaderKeetUI } from '@keethealth/ui-components';
import includes from 'lodash/includes';
import Layout from 'common/Layout';

import { getCurrentSession } from 'services/auth';
import { readProfile } from 'services/api';
import { logoutUser } from 'redux/auth/actions';
import Client from './client';

function RequireAuth({ children }) {
  const [session, setSession] = useState(false);
  const [roles, setRoles] = useState([]);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    checkRole();
  }, []);

  if (waiting) {
    return <LoaderKeetUI color="primary" />;
  }
  if (session.accessToken) {
    if (!includes(roles, 'patient')) {
      Client.getStore().dispatch(logoutUser());
      return <Redirect to={'/login'} />;
    }
    return (
      <Layout>
        {children}
      </Layout>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        // eslint-disable-next-line no-restricted-globals 
        state: { referrer: location.pathname },
      }}
    />
  );

  async function checkRole() {
    setWaiting(true);
    let newSession;
    let newRoles = [];
    try {
      newSession = await getCurrentSession();
      setSession(newSession)
      if (newSession.accessToken) {
        const profile = await readProfile();
        if (profile) {
          newRoles = profile.data.attributes.roles;
        }
      }
    } catch (e) {
      setSession(false);
    }
    setRoles(newRoles);
    setWaiting(false);
  }
}
RequireAuth.propTypes = {
  children: PropTypes.node, 
}
export default RequireAuth;
