import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash/omit';

import { createReducer } from 'utils/redux';
import { NOTIFICATION_SHOW, NOTIFICATION_HIDE } from './constants';

const initialState = {};

const notificationReducer = createReducer(initialState, {
  [NOTIFICATION_SHOW]: (state, action) => {
    const uuid = uuidv4();
    return {
      ...state,
      [uuid]: action.payload,
    };
  },
  [NOTIFICATION_HIDE]: (state, action) => omit(state, action.payload.index),
});

export default notificationReducer;
