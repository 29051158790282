import { gql } from '@apollo/client' ;

export default gql`
  mutation createObservation($input: ObservationInput!) {
    createObservation(input: {observation: $input}) {
        observation {
          questionnaireResponseId
          carePlanId
          patientId
          encounterId
          code
          status
          value
          valueType
        }
        errors {
            path
            message
        }
    }
  }
`;
