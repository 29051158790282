import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { LoaderKeetUI } from '@keethealth/ui-components';
import cN from 'classnames';

import queries from 'graph/queries';
import CURRENT_USER from 'graph/queries/currentUser';
import Panel from 'common/Panel';
import { getMessagingOtherParty } from 'utils/chat';
import Window from './Window';
import NewChat from './NewChat';

import '../messages.scss';

function Messages({ conversations, loadingConversations, userId }) {
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [isStartingChat, setIsStartingChat] = useState(false);
  const [mobileTab, setMobileTab] = useState('selector');

  useEffect(() => {
    if (!isEmpty(conversations) && isEmpty(selectedConversationId)) {
      setSelectedConversationId(head(conversations).id);
    }
  }, [conversations, selectedConversationId])

  return (
    <>
      <Panel id="scene-messages" className="flex flex-column">
        <div className="f4 bb pa3 tc">
          {mobileTab === 'messages' &&
            <button className="fl primary bn pn pointer bg-inherit cursor dn-ns db" onClick={handleBack}>
              <i className="fas fa-2x fa-arrow-left" />
            </button>
          }
          <span>Messages</span>
          <button className="fr primary bn pn pointer bg-inherit" onClick={handleNew}>
            <i className="fas fa-2x fa-plus-circle" />
          </button>
        </div>
        <div className="dn flex-m flex-l flex-auto message-height">
          {renderMessageSelector('desktop')}
          {renderWindow()}
        </div>
        <div className="dn-ns db">
          {mobileTab === 'selector' && renderMessageSelector('mobile')}
          {mobileTab === 'messages' && renderWindow()}
        </div>
      </Panel>
    </>
  );
  function handleSelectConversation(conversationId) {
    return () => {
      setSelectedConversationId(conversationId);
      setIsStartingChat(false);
      setMobileTab('messages');
    }
  }

  function handleBack() {
    setMobileTab('selector');
  }

  function handleNew() {
    setIsStartingChat(true);
    setMobileTab('messages');
  }

  function renderWindow() {
    if (loadingConversations) {
      return <LoaderKeetUI color="primary" />;
    }
    if (isStartingChat) {
      return <NewChat onSelectConversation={handleSelectConversation} />;
    }
    if (selectedConversationId) {
      return (
        <Window
          conversationId={selectedConversationId}
        />
      );
    }
  }

  function renderMessageSelector(size) {
    return (
      <ul id="messages-selector" className={`br ma0 pa0 overflow-auto ${size === 'desktop' && 'message-selector-desktop'}`}>
        {conversations.map((conversation) => {
          const otherParty = getMessagingOtherParty(userId, conversation);
          return (
            // eslint-disable-next-line
            <div
              key={conversation.id}
              onClick={handleSelectConversation(conversation.id)}
              className={cN('selector-item flex items-center link pa3 pointer', { active: conversation.id === selectedConversationId })}
            >
              <div className="flex flex-column flex-auto overflow-hidden">
                <div className="flex justify-between flex-column">
                  <span>
                    {conversation.topic}
                  </span>
                  <span className="flex-shrink-0 f6 silver">
                    {`${otherParty.firstName} ${otherParty.lastName}`}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </ul>
    );
  }
}

Messages.propTypes = {
  conversations: PropTypes.array,
  loadingConversations: PropTypes.bool.isRequired,
  userId: PropTypes.string,
};

Messages.defaultProps = {
  conversations: [],
  userId: '',
};

export default compose(
  graphql(queries.conversations, {
    props: ({ data: { loading, conversations } }) => ({
      conversations,
      loadingConversations: loading,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      userId: currentUser && currentUser.id,
    }),
  }),
)(Messages);
