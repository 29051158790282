import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import Textarea from 'react-textarea-autosize';

import queries from 'graph/queries';
import mutations from 'graph/mutations';
import '../messages.scss';

function ChatMessageForm({ practitionerId, startConversation, isCreatingChat, onSelectConversation, postMessage }) {
  const [msg, setMsg] = useState('');
  return (
    <form id="window-input" className="flex flex-shrink-0 items-center w-100 bt">
      <Textarea
        className="flex-auto pa3 bn"
        placeholder={isCreatingChat ? 'Enter conversation topic' : 'Type a message...'}
        value={msg}
        minRows={3}
        maxRows={9}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
      />
      <button
        className="bg-transparent bn h-100 w3 pointer"
        disabled={msg.length === 0}
        onClick={handleSubmit}
        type='submit'
        data-cy="send-message-btn"
        style={{
          color: process.env.REACT_APP_THEME == 'ptnow' ? 'rgb(149, 120, 211)' : '#D81F7B'
        }}
      >
        <i className="fa fa-paper-plane fa-lg" aria-hidden="true" />
      </button>
    </form>
  );

  function handleChange(e) {
    setMsg(e.target.value);
  }

  function handleKeyPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleSubmit(e);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (isCreatingChat) {
      startConversation({
        topic: msg,
        receiverId: practitionerId,
      }).then((response) => {
        const conversationId = response.data.startConversation.conversation.id;
        onSelectConversation(conversationId)();
      });
    } else {
      postMessage({
        body: msg,
      });
    }
    setMsg('');
  }
}

ChatMessageForm.propTypes = {
  practitionerId: PropTypes.string,
  startConversation: PropTypes.func,
  isCreatingChat: PropTypes.bool,
  onSelectConversation: PropTypes.func,
  postMessage: PropTypes.func,
}

export default compose(
  graphql(mutations.startConversation, {
    props: ({ mutate }) => ({
      startConversation: ({ topic, receiverId }) => mutate({
        variables: { input: { topic, receiverId } },
        refetchQueries: [{
          query: queries.conversations,
        }],
      }),
    }),
  }),
  graphql(mutations.postMessage, {
    props: ({ mutate, ownProps: { conversationId } }) => ({
      postMessage: ({ body }) => mutate({
        variables: { input: {
          body,
          conversationId,
        } },
        refetchQueries: [{
          query: queries.conversation,
          variables: { id: conversationId },
        }],
      }),
    }),
  }),
)(ChatMessageForm);
