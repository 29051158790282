import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import history from 'utils/history';
import Client from 'App/client';
import AppBody from 'App/AppBody';

import './app.scss';

export default function App() {
  return (
    <ApolloProvider client={Client.get()}>
      <Provider store={Client.getStore()}>
        <ConnectedRouter history={history}>
          <AppBody />
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  );
}
