import { gql } from '@apollo/client' ;

import MESSAGE_FRAGMENT from 'graph/fragments/message';

export default gql`
  mutation postMessage($input: MessageInput!) {
    postMessage(input: { message: $input } ) {
      message {
        ...message
      }
      errors {
        message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
