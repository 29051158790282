import { gql } from '@apollo/client' ;

export default gql`
  fragment questionnaireResponse on QuestionnaireResponse {
    fhir
    id
    patientId
    questionnaireId
    status
    authored
    scores
    questionnaireResponseItems {
      definition
      id
      position
      questionnaireItemId
      questionnaireResponseId
      text
      questionnaireResponseItemAnswers {
        questionnaireAnswerId
        questionnaireResponseItemId
        value
        valueType
      }
    }
  }
`;
