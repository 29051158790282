import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './modal.scss';

function Modal({ children, className, ...rest }) {
  return (
    <div data-testid="modal">
      <ReactModal
        {...rest}
        ariaHideApp={false}
        className={className || 'overRideDefaultClass'}
        data-testid="modal"
      >
        {children}
      </ReactModal>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Modal;
