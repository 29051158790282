import { gql } from '@apollo/client' ;
import EDUCATION_FRAGMENT from './education';
import EXERCISE_FRAGMENT from './exercise';
import QUESTIONNAIRE_FRAGMENT from './questionnaire';
import QUESTIONNAIRE_RESPONSE_FRAGMENT from './questionnaireResponse';

export default gql`
  fragment carePlanActivity on CarePlanActivity {
    id
    carePlanId
    progress
    status
    dailyAmount
    quantity
    description
    scheduledAt
    position
    updatedAt
    scores
    properties {
      count
      frequency
      duration
      durationUnit
      period
      periodUnit
    }
    scheduleTiming {
      event
    }
    plannable {
      __typename
      ... on Education {
        ...education
      }

      ... on Exercise {
        ...exercise
      }

      ... on Questionnaire {
        ...questionnaire
      }
    }
    questionnaireResponses {
      ...questionnaireResponse
    }
  }
  ${EDUCATION_FRAGMENT}
  ${EXERCISE_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
  ${QUESTIONNAIRE_RESPONSE_FRAGMENT}
`;
