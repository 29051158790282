import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './header_tab.scss';

function HeaderTab({ title, to, useTheme }) {
  const defaultTheme = {
    'border-bottom-color': '#D81F7B',
    color: '#D81F7B',
  };
  return (
    <NavLink
      to={to}
      className="header-tab bl w4 ph2 link ttu tracked tc silver"
      activeClassName="active"
      style={useTheme ? {} : defaultTheme}
    >
      {title}
    </NavLink>
  );
}

HeaderTab.propTypes = {
  useTheme: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string,
};

HeaderTab.defaultProps = {
  useTheme: true,
  title: '',
  to: '/',
  borderLeft: false,
  borderRight: false,
};

HeaderTab.displayName = 'HeaderTab';

export default HeaderTab;
