import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

import TextField from 'common/TextField';

const Text = ({ value, text, disabled, onChange }) => (
  <div className="ma3 w-80 center">
    <p className="b f5 tc" style={{ overflowWrap: 'break-word' }}>
      <Linkify properties={{ target: '_blank' }}>
        {text}
      </Linkify>
    </p>
    <TextField
      // eslint-disable-next-line jsx-a11y/no-autofocus 
      autoFocus
      id="question-textarea"
      value={value}
      onChange={onChange}
      rows={2}
      maxRows={8}
      disabled={disabled}
    />
  </div>
);

Text.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Text.defaultProps = {
  id: undefined,
  text: '',
  value: '',
  disabled: false,
  onChange: () => {},
};

export default Text;
