import { gql } from '@apollo/client' ;

const measureReportFragment = gql`
  fragment measureReport on MeasureReport {
    id
    patientId
    identifier {
        value
    }
    status
    type
    startedAt
    endedAt
    improvementNotation
    groups {
        stratifier {
            stratum {
                value
                measureScore
            }
        }
    }
  }
`;

export const measureReports = gql`
  query measureReports($identifier: String) {
    measureReports(identifier: $identifier) {
      ...measureReport
    }
  }
  ${measureReportFragment}
`;

export const measureReport = gql`
  query measureReport($id: ID!) {
    measureReports(id: $id) {
      ...measureReport
    }
  }
  ${measureReportFragment}
`;
