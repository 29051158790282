import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ src, alt }) => {
  if (!src) {
    return null;
  }

  return (
    <div className="pa3 center">
      <img src={src} alt={alt} className="w-100 mw5" />
    </div>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Logo.defaultProps = {
  alt: 'image',
};

export default Logo;
