import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { LoaderKeetUI } from '@keethealth/ui-components';
import { loginUser } from 'redux/auth/actions';
import './login.scss';

const mapDispatchToProps = dispatch => (
  {
    onLoginUser: (payload) => { dispatch(loginUser(payload)); },
  }
);

export class Confirm extends Component {
  static propTypes = {
    onLoginUser: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.handleRedirect();
  }

  handleRedirect = () => {
    const {
      onLoginUser,
      match: { params: { id } },
    } = this.props;

    onLoginUser({ CHALLENGEID: id });
  }

  render() {
    return (
      <LoaderKeetUI color="primary" />
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
)(Confirm);
