/* eslint-disable */
export default {
  plannable: {
    fhir: {
      item: [
        {
          definition: "question",
          linkId: "0eed6f08-b85c-11e9-95a1-43511b9b3cba",
          readOnly: false,
          repeats: false,
          required: true,
          text: "What is your height in feet and inches?",
          type: "height",
          code: 'Height',
        },
        {
          definition: "question",
          linkId: "0eed6f08-b85c-11e9-95a1-43511b9b3cbb",
          option: [
            { valueString: "Less than 18.5" },
            { valueString: "18.6 - 25" },
            { valueString: "25.1 - 30" },
            { valueString: "30.1 - 36" },
            { valueString: "More than 36" },
          ],
          readOnly: false,
          repeats: false,
          required: true,
          text: "What is your weight in lbs?",
          type: "number",
          code: 'Weight',
        },
        {
          definition: "question",
          linkId: "0eed6f08-b85c-11e9-95a1-43511b9b3cbc",
          option: [
            { valueString: "Less than 18.5" },
            { valueString: "18.6 - 25" },
            { valueString: "25.1 - 30" },
            { valueString: "30.1 - 36" },
            { valueString: "More than 36" },
          ],
          readOnly: false,
          repeats: false,
          required: true,
          text: "What is your BMI Score?",
          type: "choice",
          code: 'BMI',
        },
        {
          definition: "def",
          linkId: "703c1380-b85d-11e9-8f8f-77acbb9e511f",
          option: [
            { valueString: "None_I do little to no exercise" },
            { valueString: "Yes 1 to 3 days/week" },
            { valueString: "Yes 3 to 5 days/week" },
            { valueString: "At least 6 days a week AND for at least 60 minutes" },
            { valueString: "At least 6 days a week for at least 60 minutes AND my job is physically demanding (for example_you are a fire fighter)" },
          ],
          readOnly: false,
          repeats: false,
          required: true,
          text: "New topic...let's talk about exercise. Over the last 6 months, how often would you say you exercised for at least 30 minutes hard enough to need to catch your breath",
          type: "choice",
          code: 'Exercise Frequency',
        },
      ],
    },
  },
}
