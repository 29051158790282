import { gql } from '@apollo/client' ;

export default gql`
  fragment patient on Patient {
    id
    given
    active
    address
    addressPostalcode
    email
    family
    gender
    name
    phone
    organization {
      id
      name
      subdomain
    }
  }
`;
