import React from 'react';
import PropTypes from 'prop-types';

import './ExerciseContent.scss';
import GetExerciseQuantities from "../../../../Transforms/GetExerciseQuantities";

export default function ExerciseContent({properties}) {
  const { count, frequency, duration, period } = GetExerciseQuantities(properties)
  return (
    <div>
      <p>{`${count.count} REPS ${frequency.count} ${frequency.unit} ${duration.count} ${duration.unit}`}</p>
      <p>{`${period.count} ${period.unit}`}</p>
    </div>
  )
}

ExerciseContent.propTypes = {
  properties: PropTypes.object
}
