import React from "react";
import PropTypes from "prop-types";

import ChatItem from "./ChatItem";

function ChatGroup({ header, messages, patientId }) {
  return (
    <div>
      <p className='chat-group-header'>{header}</p>
      {messages.map(message => (
        <ChatItem
          key={message.id}
          isSender={message.createdBy === patientId}
          text={message.body}
          createdAt={message.createdAt}/>
      ))}
    </div>
  )
}

ChatGroup.propTypes = {
  header: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  patientId: PropTypes.string.isRequired,
}

export default ChatGroup;
