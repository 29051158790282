import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireIcon from 'assets/svg/QuestionnaireIcon';
import EducationIcon from 'assets/svg/EducationIcon';
import { Empty } from 'antd';

const ActivityIcon = ({ type, imageUrl }) => {
  switch (type) {
    case 'education':
      return <EducationIcon width="4em" height="4em" />;
    case 'exercise':
      return (imageUrl
        ? <img src={imageUrl} alt="exercise icon" className="mw-100 pa3" />
        : <i className="primary f1 icon-dumbbells" />
      );
    case 'questionnaire':
      return <QuestionnaireIcon width="4em" height="4em" />;
    case 'missing':
      return <img src={Empty.PRESENTED_IMAGE_SIMPLE} alt="missing" />;
    default:
      return <noscript />;
  }
};

ActivityIcon.propTypes = {
  type: PropTypes.oneOf(['education', 'exercise', 'questionnaire']).isRequired,
  imageUrl: PropTypes.string,
};

ActivityIcon.defaultProps = {
  imageUrl: '',
};

export default ActivityIcon;
