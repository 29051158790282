import { gql } from '@apollo/client' ;

export const npsTrackers = gql`
  query npsTracker($id: ID!) {
    nps: npsTracker(id: $id) {
      id
      encounter {
        id
        organization {
          settings {
            logoMarketing
          }
          account {
            subdomain
            defaultOrganization {
              settings {
                logoMarketing
              }
            }
          }
        }
        location {
          id
        }
      }
      trackable {
        ... on QuestionnaireResponse {
          id
          fhir
          questionnaire {
            fhir
          }
        }
      }
    }
  }
`;
