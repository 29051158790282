import React from 'react';
import PropTypes from 'prop-types';

function SelectorSearch({ value, onChange }) {
  return (
    <div className="message-search bb flex items-center pa3 silver">
      <i className="fa fa-search" aria-hidden="true" />
      <input
        placeholder="Search for a Therapist"
        className="ml3 bn flex-auto"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

SelectorSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectorSearch;
