import React from 'react';
import PropTypes from 'prop-types';

function Panel({ id, className, children }) {
  return (
    <div id={id} className="ma3 mw8 w-100 center">
      <div className={`ba-ns bt bb bg-white mh3-ns mh0 h-100 ${className}`}>
        {children}
      </div>
    </div>
  );
}

Panel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Panel.defaultProps = {
  id: '',
  className: '',
  children: <noscript />,
};

export default Panel;
