import { gql } from '@apollo/client' ;

export default gql`
  fragment content on ContentInterface {
    active
    author
    body
    contentType
    description
    id
    name
    note
    status
    title
  }
`;
