import React from 'react';

const Success = () => (
  <div className="ma3 mb5 w-80 center tc f4 mw6">
    Thank you very much for your feedback!<br /> Your comments help us discover
    new opportunities to improve our organization and make sure you have the
    best possible experience.
  </div>
);

export default Success;
