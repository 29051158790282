import React from 'react';
import { DatePicker as MDDatePicker } from 'react-md';

function DatePicker(props) {
  return (
    <div data-testid="date-picker">
      <MDDatePicker {...props} />
    </div>
  );
}

export default DatePicker;
