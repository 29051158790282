import Choice from './Choice';
import Slider from './Slider';
import Text from './Text';
import Date from './Date';
import Quantity from './Quantity';

export default {
  Choice,
  Text,
  Slider,
  Date,
  Quantity,
};
