import * as educationQueries from './educations';
import * as exerciseQueries from './exercises';
import * as carePlanQueries from './carePlans';
import * as conversationQueries from './conversations';
import * as questionnaireQueries from './questionnaires';
import * as measureReportQueries from './measureReports';
import * as questionnaireResponseQueries from './questionnaireResponses';
import * as npsTrackers from './npsTrackers';

export default {
  ...educationQueries,
  ...carePlanQueries,
  ...conversationQueries,
  ...questionnaireQueries,
  ...measureReportQueries,
  ...questionnaireResponseQueries,
  ...npsTrackers,
  ...exerciseQueries,
};
