import React from 'react';
import PropTypes from 'prop-types';

function ReportPanel({ id, className, children, title }) {
  return (
    <div id={id} className={`ba-ns bg-white pa3 ${className}`} data-testid="report-panel">
      <h3 className="ws-normal">{title}</h3>
      {children}
    </div>
  );
}

ReportPanel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ReportPanel;
