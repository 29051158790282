import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'common/DatePicker';
import isEmpty from 'lodash/isEmpty';
import Linkify from 'react-linkify';
import classNames from 'classnames';

export default class Date extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onIncomplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.string,
      valueType: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    answer: {
      value: '',
      valueType: 'string',
    },
    disabled: false,
    isAnswerType: false,
    readOnly: false,
  }

  state = {
    value: this.props.answer.value,
  }

  componentDidMount() {
    const { onAnswer, id, onComplete, required } = this.props;
    const { value } = this.state;
    if (value === '') {
      onAnswer(id, {});
      if (!required) {
        onComplete();
      }
    }
  }

  handleChange = (value) => {
    const { onAnswer, id, onComplete, onIncomplete, required } = this.props;
    onAnswer(id, { value, valueType: 'date' });
    this.setState({
      value,
    });
    if (required && isEmpty(value)) {
      onIncomplete();
    } else {
      onComplete();
    }
  }

  render() {
    const { text, disabled, isAnswerType, readOnly } = this.props;
    const { value } = this.state;

    return (
      <div className="ma3 w-80 center">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <div className="w4">
          <DatePicker
            id="question-datepicker"
            value={value}
            onChange={this.handleChange}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
