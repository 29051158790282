import { gql } from '@apollo/client' ;
import CONTENT_FRAGMENT from './content';

export default gql`
  fragment education on Education {
    isHelpful
    readCount
    isTemplate
    bodyPart
    diagnosis
    saveToClinic
    ...content
  }
  ${CONTENT_FRAGMENT}
`;
