import generateSnippet from './snippet';
import { filterMap } from '../../utils/utils';

let PENDO_API_KEY;
let DEPLOY_ENV;

const {
  REACT_APP_PENDO_API_KEY,
  REACT_APP_DEPLOY_ENV,
} = process.env;

try {
  PENDO_API_KEY = REACT_APP_PENDO_API_KEY;
  DEPLOY_ENV = REACT_APP_DEPLOY_ENV;
} catch (e) {
  console.warn('PENDO API KEY IS NOT DEFINED');
}

const envContext = {
  domain: 'patient',
  environment: DEPLOY_ENV,
};

const shouldRecordEvents = DEPLOY_ENV !== 'local';

class Pendo {
  static init(currentUser = {}) {
    if (shouldRecordEvents) {
      Pendo.setVisitor(currentUser);

      if (!Pendo.isInitialized()) {
        const script = document.createElement('script');
        script.id = 'pendo-snippet';
        script.innerHTML = generateSnippet(PENDO_API_KEY);

        const scripts = document.getElementsByTagName('script');
        const firstChild = scripts[0];
        firstChild.parentNode.insertBefore(script, firstChild);

        // NOTE: Is this a race condition?
        const agentScript = document.getElementById('pendo-agent');
        if (agentScript) {
          agentScript.onload = Pendo.onLoad;
        }
      }
    }
  }

  static isInitialized() {
    return !!document.getElementById('pendo-snippet');
  }

  static reset() {
    // TODO: [#174756838] We should really unload pendo, not just set to guest.
    const pendo = Pendo.get();
    if (pendo) {
      pendo.updateOptions({ visitor: { id: 'guest' } });
    }
  }

  static onLoad() {
    Pendo.get().initialize(Pendo.getVisitor());
  }

  static get() {
    return window.pendo;
  }

  static setVisitor(currentUser) {
    this.visitor = Pendo.getContext(currentUser);
  }

  static getVisitor() {
    return this.visitor;
  }

  static getContext(currentUser) {
    const { organization, ...user } = currentUser;
    const account = organization.account || {};
    const settings = organization.settings || {};

    return {
      visitor: {
        ...envContext,
        id: user.id,
        email: user.email,
        name: `${user.given} ${user.family}`,
        first_name: user.given,
        last_name: user.family,
        organization_id: organization.id,
      },

      account: {
        ...envContext,
        account_id: organization.id,
        name: organization.name,
        organization_account_primary: account.defaultOrganization.id,
        organization_integration_enabled: settings.integrationEnabled,
        organization_customer_type: settings.customerType,
        organization_name: organization.name,
        organization_subdomain: organization.subdomain,
        organization_emr_type: organization.identifiers.map((identifier) => identifier.system),
        organization_features: filterMap(
          organization.features,
          ({ code, enabled }) => enabled && code,
        ),
        organization_workflows: filterMap(
          organization.features,
          ({ code, workflows_enabled }) => workflows_enabled && code,
        ),
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },

      parentAccount: {
        ...envContext,
        account_id: account.id,
        name: account.name,
      },
    };
  }
}

export default Pendo;
