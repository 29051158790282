import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { gql } from '@apollo/client' ;
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { LoaderKeetUI } from '@keethealth/ui-components';
import Questionnaire from 'scenes/Questionnaire';
import { Content } from 'scenes/Education';
import TaskDetail from 'scenes/TaskDetail';
import { findNext } from 'utils/utils';
import { transformActivities } from 'utils/carePlans';
import observationProps from 'test_constants/observation';
import '../care_plan.scss';

function CarePlanActivity({ loading, match, carePlanActivity, carePlans, education }) {
  const { path, params : { taskId } } = match;
  if (loading) return <LoaderKeetUI color="primary" />;
  if (path.includes('/observation')) {
    return <Questionnaire {...observationProps} getNextActivity={getNextActivity} />;
  }
  switch (carePlanActivity.plannable.__typename) {
    case 'Questionnaire':
      return (
        <Questionnaire loading={loading} match={match} carePlanActivity={carePlanActivity} getNextActivity={getNextActivity} />
      );
    case 'Education':
      return (
        <Content loading={loading} education={education} match={match} carePlanActivity={carePlanActivity} getNextActivity={getNextActivity} />
      );
    case 'Exercise':
      return (
        <TaskDetail loading={loading} match={match} carePlanActivity={carePlanActivity} getNextActivity={getNextActivity} />
      );
    default:
      return null;
  }
  function getNextActivity() {

    const activeActivities = transformActivities(carePlans);

    if (!isEmpty(activeActivities)) {
      const currentIndex = findIndex(activeActivities, { id: taskId });
      const nextActivity = findNext(activeActivities, currentIndex, (cpa) => cpa.status !== 'completed');
      if (nextActivity) return nextActivity.id;
    }
    // return to beginning
    return null;
  }
}

CarePlanActivity.propTypes = {
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      taskId: PropTypes.string,
    }),
  }).isRequired,
  carePlanActivity: PropTypes.shape({
    plannable: PropTypes.shape({
      __typename: PropTypes.string.isRequired,
    }),
  }),
  carePlans: PropTypes.arrayOf(
    PropTypes.shape({
      carePlanActivities: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          required: PropTypes.bool,
        }),
      ),
    }),
  ),
  education: PropTypes.object,
};

CarePlanActivity.defaultProps = {
  carePlans: [],
  carePlanActivity: undefined,
}

export default compose(
  graphql(gql`
  query carePlans($id: ID!) {
    carePlanActivity(id: $id) {
      id
      carePlanId
      properties {
        count
        frequency
        duration
        durationUnit
        period
        periodUnit
      }
      plannable {
        __typename
        ... on Education {
          id
        }
        ... on Exercise {
          id
        }
        ... on Questionnaire {
          id
        }
      }
    }

    carePlans {
      id
      status
      startDate
      carePlanActivities {
        id
        status
        dailyAmount
        properties {
          count
          frequency
          duration
          durationUnit
          period
          periodUnit
        }
        scheduleTiming {
          event
        }
      }
    }
  }
`, {
    props: ({ data: { loading, carePlans, carePlanActivity } }) => ({
      loading,
      carePlans,
      carePlanActivity,
    }),
    options: ({ match: { params: { taskId: id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
)(CarePlanActivity);
