import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MOCK !== 'none'
  ) {
    const { worker } = require('./mocks/browser'); //eslint-disable-line
    worker.start();
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
  });
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  );
})();

