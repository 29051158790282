import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import queries from 'graph/queries';
import Panel from 'common/Panel';
import { transformActivities } from 'utils/carePlans';
import '../care_plan.scss';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { Empty, Table } from 'antd';
import { orderByDate } from 'utils/carePlans';
import TitlePanel from '../../../common/TitlePanel';
import Tabs from '../../../common/Tabs';
import ContentCard from '../../../common/ContentCard';
import history from '../../../utils/history';
import QuestionnaireContent from '../../../common/ContentCard/ContentCardContent/QuestionnaireContent';
import ExerciseContent from '../../../common/ContentCard/ContentCardContent/ExerciseContent';
import EducationContent from '../../../common/ContentCard/ContentCardContent/EducationContent';
import EducationIcon from '../../../assets/svg/EducationIcon';
import QuestionnaireIcon from '../../../assets/svg/QuestionnaireIcon';
import { getDateFromUTCString } from 'utils/time';

export function CarePlan({ loading, carePlans }) {
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedTab, setSelectedTab] = useState('exercise');
  const [isContainNew, setIsContainNew] = useState({
    exercise: 0,
    questionnaire: 0,
    education: 0,
  });
  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'title',
      key: 'title',
      sorter: sortByTitle,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: renderRow,
    },
  ];
  const [isFirstRendering, setIsFirstRendering] = useState(false);

  useEffect(() => {
    if (carePlans.length === 0) {
      setIsFirstRendering(true);
    }
  }, []);

  useEffect(() => {
    const activities = transformActivities(carePlans);
    let newExerciseCnt = 0;
    let newQuestionnaireCnt = 0;
    let newEducationCnt = 0;

    forEach(activities, (activity) => {
      switch (activity.plannable.__typename.toLowerCase()) {
        case 'exercise':
          newExerciseCnt = activity.status === 'not_started' ? newExerciseCnt + 1 : newExerciseCnt;
          break;
        case 'questionnaire':
          newQuestionnaireCnt =
            activity.status === 'not_started' ? newQuestionnaireCnt + 1 : newQuestionnaireCnt;
          break;
        case 'education':
          newEducationCnt =
            activity.status === 'not_started' ? newEducationCnt + 1 : newEducationCnt;
          break;
        default:
          break;
      }
    });

    setIsContainNew({
      exercise: newExerciseCnt,
      questionnaire: newQuestionnaireCnt,
      education: newEducationCnt,
    });

    if (isFirstRendering === true) {
      setIsFirstRendering(false);
    }
  }, [carePlans]);

  const activityList = getData();
  return (
      <div data-testid='care-plan'>
        <TitlePanel className="pa3 flex items-center justify-center">
          <h2 className="tracked mb0">My Tasks</h2>
        </TitlePanel>
        <Tabs
          tabs={[
            {
              label: 'Exercises',
              name: 'exercise',
              newCount: isContainNew.exercise,
            },
            {
              label: 'Questionnaires',
              name: 'questionnaire',
              newCount: isContainNew.questionnaire,
            },
            {
              label: 'Education',
              name: 'education',
              newCount: isContainNew.education,
            },
          ]}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
        <Panel id={'scene-careplan'} className="flex flex-column pa3-ns">
          <div className="w-100 overflow-auto ph4-ns ph0">
            {!loading && activityList.length === 0 ? (
              renderEmptyText()
            ) : (
              <Table
                onRow={(record) => ({
                  onClick: handleRowClick(record),
                })}
                columns={columns}
                dataSource={activityList}
                pagination={false}
                loading={isFirstRendering ? loading : false}
                onChange={handleChange}
                className="pointer"
              />
            )}
          </div>
        </Panel>
      </div>
  );
  function handleChange(pagination, filters, sorter) {
    setSortedInfo(sorter);
  }
  function handleRowClick(record) {
    return (e) => {
      e.preventDefault();
      history.push(record.path);
    };
  }
  function renderEmptyText() {
    return (
      <div className="pa4 tc">
        <h3 className="ws-normal">{"You don't have any educational materials yet."}</h3>
        <h4>{"We'll let you know when you do."}</h4>
      </div>
    );
  }
  function getData() {
    const activeActivities = transformActivities(carePlans);
    const activities = activeActivities.map((activity) => {
      const { id, plannable, status } = activity;
      const isCompleted = status === 'completed';
      const isQuestionnaire = plannable && plannable.__typename === 'Questionnaire';
      const disabled = (isQuestionnaire && isCompleted) || isEmpty(plannable);
      let path = disabled ? '' : `/my_tasks/${id}/`;

      return {
        ...activity,
        key: activity.id,
        type: activity.plannable.__typename.toLowerCase(),
        title: activity.plannable.title,
        description: activity.plannable.description,
        path,
      };
    });
    return orderByDate(activities.filter((activity) => activity.type === selectedTab), 'scheduledAt', 'desc');
  }
  function handleTabChange(tabName) {
    setSelectedTab(tabName);
  }
  function sortByTitle(a, b) {
    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
    const nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  function renderRow(title, record) {
    const date = getDateFromUTCString(record.updatedAt).format('MM/DD/YYYY');
    const { type } = record;
    let icon;
    switch (type) {
      case 'education':
        icon = <EducationIcon width="4em" height="4em" />;
        break;
      case 'exercise':
        icon = get(record.plannable, 'photoUrl') ? (
          <img src={record.plannable.photoUrl} alt="exercise icon" className="mw-100 pa3" />
        ) : (
          <i className="primary f1 icon-dumbbells" />
        );
        break;
      case 'questionnaire':
        icon = <QuestionnaireIcon width="4em" height="4em" />;
        break;
      case 'missing':
        icon = <img src={Empty.PRESENTED_IMAGE_SIMPLE} alt="missing" />;
        break;
      default:
        icon = <noscript />;
    }
    let footerText;
    switch (true) {
      case record.status === 'not_started':
        footerText = `Assigned on: ${date}`;
        break;
      case record.status === 'in_progress':
        footerText = `Started on: ${date}`;
        break;
      default:
        footerText = `Completed on: ${date}`;
    }
    return (
      <ContentCard icon={icon} title={title} footer={footerText} status={record.status}>
        {type === 'questionnaire' && <QuestionnaireContent {...record} />}
        {type === 'exercise' && <ExerciseContent {...record} />}
        {type === 'education' && <EducationContent {...record} />}
      </ContentCard>
    );
  }
}

CarePlan.propTypes = {
  carePlans: PropTypes.arrayOf(
    PropTypes.shape({
      carePlanActivities: PropTypes.arrayOf(
        PropTypes.shape({
          plannable: PropTypes.shape({
            title: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  loading: PropTypes.bool.isRequired,
};

CarePlan.defaultProps = {
  carePlans: [],
  loading: false,
};

export default compose(
  graphql(queries.carePlansIncomplete, {
    options: {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
    },
    props: ({ data }) => {
      const { loading, carePlans } = data;
      return {
        carePlans,
        loading,
      };
    },
  }),
  graphql(queries.taskStats, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
    }),
    props: ({ data: { loading, carePlanStats } }) => ({
      carePlanStats,
      loadingTaskStats: loading,
    }),
  }),
)(CarePlan);
