import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { ButtonKeetUI } from '@keethealth/ui-components';
import map from 'lodash/map';
import { Link } from 'react-router-dom';

import LoginPanel from 'common/LoginPanel';
import { loginUser } from 'redux/auth/actions';
import keetLogo from 'assets/images/keet-logo-large.png';
import ptnowLogo from 'assets/images/pt-now-header-logo.png';
import headerBG from 'assets/images/Product-Header-BG.png';
import { getNotifications } from 'redux/notification';
import './login.scss';

const mapDispatchToProps = dispatch => (
  {
    onLoginUser: () => { dispatch(loginUser()); },
  }
);

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const bgStyle = { backgroundImage: `url(${headerBG})` };

export class Login extends Component {
  static propTypes = {
    onLoginUser: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
  }

  static defaultProps = {
    notifications: {},
  }

  handleLogin = (event) => {
    event.preventDefault();
    const { onLoginUser } = this.props;
    onLoginUser();
  }

  renderMessage = content =>
    <Alert className="alert" message={content} type="error" showIcon />;

  render() {
    const { notifications } = this.props;

    return (
      <div data-testid='login' className="flex w-100 h-100 cover bg-center cover items-center min-vh-100 z-1" style={bgStyle}>
        <LoginPanel className="w-100 panel flex flex-column justify-center items-center pa4 br2">
          <img src={process.env.REACT_APP_THEME == 'ptnow' ? ptnowLogo : keetLogo} alt={`${process.env.REACT_APP_THEME == 'ptnow' ? 'PTNow' : 'Keet'} Logo`} />
          {map(notifications, ({ text }) => this.renderMessage(text))}
          <form className="w5 login-screen" onSubmit={this.handleLogin}>
            <ButtonKeetUI className="mt4 center w-auto mw-100 loginButton br2 f4 fw4 mb4" type="submit" data-cy="legacy-auth-login-button">Click here to log in</ButtonKeetUI>
          </form>
          <Link to="/register" className="pointer registration-link w5 login-screen tc">
            I have a registration code
          </Link>
        </LoginPanel>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Login);
