import throttle from 'lodash/throttle';
import { Modal } from 'antd';
import { logoutUser } from 'redux/auth/actions';
import Client from 'App/client';
import Timer from '../utils/timer';
import { getCurrentSession } from './auth';

const minutesToMillis = mins => mins * 60 * 1000;

const logout = () => {
  Client.getStore().dispatch(logoutUser());
};

const sessionRefresh = throttle(async () => {
  const session = await getCurrentSession();
  if (!session) {
    logout();
  }
}, 15 * 1000);

const resetTimer = async () => {
  sessionRefresh();
  Timer.start({
    key: 'active',
    cb: () => {
      /* eslint-disable-next-line */
      let secondsToGo = 10;

      document.body.removeEventListener('click', resetTimer);

      /* set up modal */
      const modal = Modal.info({
        title: 'Inactivity',
        content: `You will automatically be logged out in ${secondsToGo} seconds.`,
        okText: "I'm still here",
        maskClosable: false,
        okButtonProps: {
          size: 'large',
        },
      });

      /* every second, we will update the content */
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `You will automatically be logged out in ${secondsToGo} seconds.`,
        });
      }, 1000);

      /* if no response from user, this timeout will occur */
      const timeout = setTimeout(() => {
        clearInterval(timer);
        Timer.clear('active');
        modal.destroy();
        logout();
      }, secondsToGo * 1000);

      /* if user presses ok, we'll clear this timeout */
      const onUserActivity = () => {
        clearTimeout(timeout);
        clearInterval(timer);
        resetTimer();
        document.body.addEventListener('click', resetTimer);
      };

      /* we'll update the modal here, so we have reference to the timer */
      modal.update({
        onOk: onUserActivity,
      });
    },
    expiresIn: minutesToMillis(30),
  });
};

export default {
  init() {
    resetTimer();
    document.body.addEventListener('click', resetTimer);
  },
  cleanUp() {
    Timer.clear('active');
    document.body.removeEventListener('click', resetTimer);
  },
};
