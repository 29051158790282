import React from 'react';
import PropTypes from 'prop-types';

import BaseContent from "./BaseContent";
import './ContentCardContent.scss';

export default function ContentCardContent({ title, icon, children }) {
  return (
    <BaseContent title={title} icon={icon}>
      {children}
    </BaseContent>
  )
}

ContentCardContent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
}
