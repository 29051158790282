import { gql } from '@apollo/client' ;

import PATIENT_FRAGMENT from 'graph/fragments/patient';
import USER_FRAGMENT from 'graph/fragments/currentUser';

export default gql`
  mutation updateUser(
    $given: String,
    $family: String,
    $phone: String,
    $email: String,
    $addressPostalcode: String,
    $username: String,
  ) {
    updateUser(input: {
      given: $given,
      family: $family,
      phone: $phone,
      email: $email,
      addressPostalcode: $addressPostalcode,
      username: $username,
    }) {
      patientRecord {
        ...patient
      }
      currentUser {
        ...currentUser
      }
      errors {
        path
        message
      }
    }
  }
  ${PATIENT_FRAGMENT}
  ${USER_FRAGMENT}
`;
