import { gql } from '@apollo/client' ;

export default gql`
  query patientRecord {
    patientRecord {
      given
      active
      address
      addressPostalcode
      email
      family
      gender
      id
      name
      phone
      organization {
        id
        name
        subdomain
      }
      creator {
        accountId
        id
      }
    }
  }
`;
