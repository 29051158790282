import { gql } from '@apollo/client' ;

export default gql`
  fragment currentUser on User {
    username
    id
    organization {
      id
      name
      subdomain
      identifiers {
        system
      }
      features {
        code
        enabled
        workflowsEnabled
      }
      account {
        id
        name
        defaultOrganization {
          id
        }
      }
      settings {
        integrationEnabled
        customerType
      }
    }
  }
`;
