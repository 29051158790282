import { gql } from '@apollo/client' ;

import CONVERSATION_FRAGMENT from 'graph/fragments/conversation';

export const conversations = gql`
  query conversations {
    conversations {
      ...conversation
    }
  }
  ${CONVERSATION_FRAGMENT}
`;

export const conversation = gql`
  query conversation($id: ID!) {
    conversation(id: $id) {
      ...conversation
    }
  }
  ${CONVERSATION_FRAGMENT}
`;
