import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { ErrorBoundary } from 'react-error-boundary';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import NotificationContainer from 'common/NotificationContainer';
import RequireAuth from './RequireAuth';
import { Login, Authorize, Confirm, Register } from '../scenes/Login';
import CarePlan, { CarePlanActivity } from 'scenes/CarePlan';
import Progress from 'scenes/Progress';
import Education, { Content } from 'scenes/Education';
import History from 'scenes/History';
import Messages from 'scenes/Messages';
import Settings from 'scenes/Settings';
import Task from 'scenes/TaskDetail';
import Questionnaire, { CompletedQuestionnaire } from 'scenes/Questionnaire';
import SignatureHandler from '../scenes/SignatureHandler/SignatureHandler';
import QRTask from 'scenes/QRTask';
import NPS from 'scenes/NPS';
import Error404 from 'common/Error404';
import ErrorPage from 'common/ErrorPage';

function AppBody() {

  useEffect(() => {
    document.body.dataset.theme = process.env.REACT_APP_THEME || 'keet';
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, info) => {}}
    >
      <Switch>
        {/* PUBLIC */}
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/authorize" exact component={Authorize} />
        <Route path="/confirm/:id" exact component={Confirm} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route
          path="/qr_task/:resourceId/:signature?"
          exact
          render={(props) => (
            <SignatureHandler {...props} path="qr_tasks" suffix="0" />
          )}
        />
        <Route
          path="/qr_tasks/:taskId/:questionIndex?"
          exact
          component={QRTask}
        />
        <Route
          path="/nps/:resourceId"
          exact
          render={(props) => <SignatureHandler {...props} path="nps_survey" />}
        />
        <Route path="/nps_survey/:npsId" exact component={NPS} />
        {/* PRIVATE */}
        <Route
          path="/my_tasks"
          exact
          render={(props) => (
            <RequireAuth>
              <CarePlan {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/my_tasks/:taskId/:questionIndex?"
          exact
          render={(props) => (
            <RequireAuth>
              <CarePlanActivity {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/progress"
          exact
          render={(props) => (
            <RequireAuth>
              <Progress {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/education"
          exact
          render={(props) => (
            <RequireAuth>
              <Education {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/education/:taskId"
          exact
          render={(props) => (
            <RequireAuth>
              <Content {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/history"
          exact
          render={(props) => (
            <RequireAuth>
              <History {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/messages"
          exact
          render={(props) => (
            <RequireAuth>
              <Messages {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/settings"
          exact
          render={(props) => (
            <RequireAuth>
              <Settings {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/exercise/:taskId"
          exact
          render={(props) => (
            <RequireAuth>
              <Task {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/questionnaire/:questionnaireId"
          exact
          render={(props) => (
            <RequireAuth>
              <Questionnaire {...props} />
            </RequireAuth>
          )}
        />
        <Route
          path="/completed_questionnaire/:taskId"
          exact
          render={(props) => (
            <RequireAuth>
              <CompletedQuestionnaire {...props} />
            </RequireAuth>
          )}
        />
        {/* 404 */}
        <Route component={Error404} />
      </Switch>
      <NotificationContainer />
    </ErrorBoundary>
  );
}

export default compose(withRouter)(AppBody);
