import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import Linkify from 'react-linkify';
import classNames from 'classnames';
import { optionToValue } from 'utils/form';
import './MultiChoice.scss';

const CheckboxGroup = Checkbox.Group;

export default class MultiChoice extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    option: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      valueType: PropTypes.string,
    })).isRequired,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onIncomplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    answer: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      valueType: PropTypes.string,
    })),
    disabled: PropTypes.bool,
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    answer: [],
    disabled: false,
    isAnswerType: false,
    readOnly: false,
  }

  state = {
    values: map(Array.isArray(this.props.answer) ? this.props.answer : [this.props.answer], answer => answer.value),
  }

  componentDidMount() {
    const { id, onAnswer, onComplete, required } = this.props;
    const { values } = this.state;
    if (typeof values === 'undefined' || isEmpty(values)) {
      onAnswer(id, []);
    }
    if (!required) {
      onComplete();
    }
  }

  options = this.props.option;

  handleSelect = (values) => {
    const { onAnswer, id, onComplete, required, onIncomplete } = this.props;
    const checkedOptions = values.map(value => ({ ...find(this.options, ['value', value]) }));
    onAnswer(id, checkedOptions);
    this.setState({
      values,
    });
    if (required && isEmpty(checkedOptions)) {
      onIncomplete();
    } else {
      onComplete();
    }
  }

  renderOptions = () => map(this.options, (option, idx) => {
    const { id, readOnly } = this.props;
    let value = optionToValue(option).value;
    value = readOnly ? value.toString() : value;

    return (
      <Checkbox key={`${id}.option.items[${idx}]`} value={value} className="checkboxStyle">{value}</Checkbox>
    );
  });

  render() {
    const { text, disabled, isAnswerType, readOnly } = this.props;
    const { values } = this.state;

    return (
      <div data-testid='multiple-choice-question' className="ma3 w-80 center">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <CheckboxGroup className="w-100 tl" value={values} onChange={this.handleSelect} disabled={disabled}>
          {this.renderOptions()}
        </CheckboxGroup>
      </div>
    );
  }
}
