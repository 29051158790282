import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { LoaderKeetUI } from '@keethealth/ui-components';
import { checkAuthorizationCallback } from 'redux/auth/actions';
import './login.scss';

const mapDispatchToProps = dispatch => (
  {
    onAuthorizationResponse: () => { dispatch(checkAuthorizationCallback()); },
  }
);

export class Authorize extends Component {
  static propTypes = {
    onAuthorizationResponse: PropTypes.func.isRequired,
  }

  static defaultProps = {
    notifications: {},
  }

  componentDidMount() {
    this.handleRedirect();
  }

  handleRedirect = () => {
    const { onAuthorizationResponse } = this.props;
    onAuthorizationResponse();
  }

  render() {
    return (
      <LoaderKeetUI color="primary" />
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
)(Authorize);
