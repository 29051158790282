import findKey from 'lodash/findKey';
import isNil from 'lodash/isNil';
import lowerCase from 'lodash/lowerCase';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import head from 'lodash/head';
import get from 'lodash/get';
import keys from 'lodash/keys';
import startsWith from 'lodash/startsWith';
import { flattenQuestionnaireItems } from 'utils/utils';

const normalizeValueObject = (value) => {
  const valueKey = findKey(value, v => !isNil(v));
  return {
    value: value[valueKey],
    valueType: lowerCase(replace(valueKey, 'value', '')),
  };
};

const getQuestionAnswer = (questionId, questionnaireResponses) => {
  if (!isEmpty(questionnaireResponses)) {
    const responseItem = find(questionnaireResponses, { linkId: questionId });
    const answer = responseItem && responseItem.answer;
    if (!isNil(answer)) {
      if (answer.length > 1) {
        return responseItem.answer.map(ans => normalizeValueObject(ans));
      }
      return normalizeValueObject(head(answer));
    }
    return null;
  }
  return null;
};

export const getQuestionsObject = (questionsArray, questionnaireResponses = []) => {
  const questionsObject = {};
  const firstResponse = JSON.parse(get(questionnaireResponses, '[0].fhir', '{}'));
  const flattenedResponseItems = flattenQuestionnaireItems(firstResponse.item);
  questionsArray.forEach((question, index) => {
    const answer = getQuestionAnswer(question.linkId, flattenedResponseItems);
    questionsObject[question.linkId] = {
      index,
      ...question,
      answer,
    };
  });
  return questionsObject;
};

export const optionToValue = (option) => {
  const optionKeys = keys(option);
  const valueKey = find(optionKeys, o => startsWith(o, 'value'));

  return {
    ...(option.extension ? { extension: option.extension } : {}),
    value: option[valueKey],
    valueType: lowerCase(replace(valueKey, 'value', '')),
  };
};
