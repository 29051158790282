import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const STATE_KEY = '@keethealth/currentState';
const getStorageKey = (state) => `@keethealth/state/${state}`;

const removeItemFromStorage = (key) => {
  localStorage.removeItem(key);
};

const setCurrentStateKey = (state) => {
  localStorage.setItem(STATE_KEY, state);
};

const getCurrentStateKey = () => getStorageKey(localStorage.getItem(STATE_KEY));

export function getStateFromStorage(state) {
  if (isEmpty(state)) {
    return null;
  }
  const key = getStorageKey(state);

  let stateFromStorage;
  try {
    stateFromStorage = JSON.parse(localStorage.getItem(key));
  } catch (e) {
    stateFromStorage = null;
  }
  const currentTime = moment().valueOf();

  if (isEmpty(stateFromStorage) || currentTime > stateFromStorage.expiresAt) {
    return null;
  }

  removeItemFromStorage(STATE_KEY);
  removeItemFromStorage(key);

  return stateFromStorage.values;
}

function getStorageKeyExpiration() {
  const addMinutes = moment()
    .add(5, 'm')
    .valueOf();
  return addMinutes;
}

export function setStateInStorage(state, values) {
  removeItemFromStorage(getCurrentStateKey());
  setCurrentStateKey(state);
  const expiresAt = getStorageKeyExpiration();
  const key = getStorageKey(state);

  localStorage.setItem(key, JSON.stringify({ values, expiresAt }));
}
