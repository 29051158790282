import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChatSuggestions from './ChatSuggestions';
import CreateChat from './CreateChat';
import SelectorSearch from './SelectorSearch';
import '../messages.scss';

function NewChat({ onSelectConversation }) {
  const [newChatTherapist, setNewChatTherapist] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div className="messages-window flex flex-column flex-auto">
      {!newChatTherapist &&
        <SelectorSearch value={searchQuery} onChange={handleChange} />
      }
      {newChatTherapist
        ? (
          <CreateChat onSelectConversation={onSelectConversation} practitioner={newChatTherapist} />
        )
        : (
          <ChatSuggestions searchQuery={searchQuery} onBeginCreation={handleBeginCreation} />
        )
      }
    </div>
  );

  function handleBeginCreation(practitioner) {
    return () => {
      setNewChatTherapist(practitioner);
    }
  }

  function handleChange({ target: { value } }) {
    setSearchQuery(value);
  }
}

NewChat.propTypes = {
  onSelectConversation: PropTypes.func.isRequired,
};

export default NewChat;
