import { inflect } from 'inflection';

export const DURATION_UNITS = {
  Second: 's',
  Minute: 'm',
  Hour: 'h',
  Day: 'd',
  Week: 'wk',
  Month: 'mo',
};

export default exercise => {
  const {
    count = 0,
    frequency = 0,
    duration = 0,
    durationUnit = 'Second',
    period = 0,
    periodUnit = 'Day',
  } = exercise;

  return {
    count: {
      count: count || 0,
      unit: inflect('Rep', count),
    },
    frequency: {
      count: frequency || 0,
      unit: inflect('Set', frequency),
    },
    duration: {
      count: duration && durationUnit ? `${duration}${DURATION_UNITS[durationUnit]}` : '0s',
      unit: 'Hold',
    },
    period: {
      count: `${period || 0}x`,
      unit: `Per ${periodUnit}`,
    },
  };
};
