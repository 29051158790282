import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/Modal';
import { ButtonKeetUI } from '@keethealth/ui-components';
import './CompletedModal.scss';

function CompletedModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen}>
      <div id="completed-modal" className="pa3 pt5 mw6 w-100 pb4 center">
        <h1 className="tc">HOORAY!</h1>
        <p className="ttu fw5 mb4 tc">You&#39;ve answered all our questions!</p>
        <div className="flex justify-center">
          <h1 className="f1 b br-100 br1--solid b--solid flex items-center justify-center mb4 hooray-circle">100%</h1>
        </div>
        <ButtonKeetUI
          className="center ma3 mw5 w-100"
          onClick={onClose}
        >
          DONE
        </ButtonKeetUI>
      </div>
    </Modal>
  );
}

CompletedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CompletedModal;
