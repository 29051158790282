import { gql } from '@apollo/client' ;

export default gql`
  fragment questionnaire on Questionnaire {
    id
    name
    status
    description
    purpose
    subjectType
    title
    fhir
    properties {
      displayGraph
    }
    questionnaireItems {
      id
      text
      definition
      itemType
      readOnly
      repeats
      required
      maxLength
      questionnaireAnswers {
        id
        value
        valueType
      }
    }
  }
`;
