import ApiClient from 'App/apiClient';

export async function readProfile() {
  return ApiClient.profile.read();
}

export async function validateRegistrationCode(params) {
  return ApiClient.auth.validateRegistrationCode(params);
}

export async function registerPatient(params) {
  return ApiClient.auth.registerPatient(params);
}
